import React from 'react';
import { Grid, Box } from '@cheese-fondue/components/grid';

import { media } from '@cheese-fondue/helpers';
import { COLORS } from '@cheese-fondue/styles';
import { speckledBackground } from '@cheese-fondue/styles/backgrounds';
import styled from 'styled-components';
import { Text } from '@cheese-fondue/components/atoms';
import { Container } from '@cheese-fondue/styles/container';
import { Accordion, AccordionItem } from '../accordion';
import { Timeline, TimelineItem } from '../timeline';
import { Link } from '@components/core';
import { typography } from '@cheese-fondue/styles/typography';
import tiltLogo from '../../../../assets/images/tilt.svg';
import backchannelLogo from '../../../../assets/images/backchannel.svg';
import oracleLogo from '../../../../assets/images/oracle-alpha.svg';
import forecastLogo from '../../../../assets/images/forecastos.png';

const Product = ({
  title,
  text,
  bgColor,
  imageSrc,
}: {
  title: string;
  text: string;
  bgColor: string;
  imageSrc: any;
}): React.ReactElement => (
  <ProductItem>
    <ProductImage bgColor={bgColor}>
      <ProductLogo src={imageSrc} alt={title} />
    </ProductImage>
    <Text as="p" color={COLORS.orange100} size={['h2']}>
      {title}
    </Text>
    <Text as="p" mb={'one'} style={{ marginTop: '-8px' }} color={COLORS.orange100} size={['pMedium']}>
      {text}
    </Text>
  </ProductItem>
);

const DelphiaJourney = (): React.ReactElement => {
  const [tab, setTab] = React.useState<'timeline' | 'products'>('timeline');

  return (
    <Wrapper>
      <Container>
        <Grid>
          <Box cols={[12, 12]} alignY={'center'} style={{ flexDirection: 'column' }}>
            <Text
              style={{ maxWidth: '20ch', textWrap: 'balance' }}
              as="h2"
              textAlign="center"
              color={COLORS.orange100}
              size={['h2Variant', 'h1XLarge']}
            >
              A Retrospective
            </Text>
            <TabSwitcherWrapper>
              <TabSwitcher>
                <TabButton onClick={() => setTab('timeline')} isSelected={tab === 'timeline'}>
                  Our Journey
                </TabButton>
                <TabButton onClick={() => setTab('products')} isSelected={tab === 'products'}>
                  Our Products
                </TabButton>
              </TabSwitcher>
            </TabSwitcherWrapper>
          </Box>
        </Grid>
        <TimeLineWrapper>
          {tab === 'products' ? (
            <ProductsList>
              <Product
                imageSrc={oracleLogo}
                bgColor="#FFE5A4"
                title="Oracle Alpha"
                text="A market-neutral quant equity hedge fund"
              />

              <Product
                bgColor={COLORS.white100}
                title="ForecastOS"
                imageSrc={forecastLogo}
                text="A platform for building systematic investment strategies"
              />

              <Product
                bgColor="#EEFC2C"
                title="Tilt"
                imageSrc={tiltLogo}
                text="A way to invest in trends that you think will move the market"
              />

              <Product
                imageSrc={backchannelLogo}
                bgColor="#DEFF80"
                title="Backchannel"
                text="A way for experts to own and monetize their knowledge"
              />
            </ProductsList>
          ) : (
            <Accordion defaultIndex={0} id="masterplan-accordion">
              <AccordionItem textAlignment="left" backgroundColor={COLORS.purple400} title="Pre-Delphia">
                <Timeline>
                  <TimelineItem date="2011">
                    <p>
                      Future Delphia Co-founder, Cliff van der Linden, pioneers a new way to measure public opinion
                      from big data
                    </p>
                  </TimelineItem>
                  <TimelineItem date="2013">
                    <p>
                      With a team of data scientists, van der Linden founds{' '}
                      <Link to="http://www.voxpoplabs.com/">Vox Pop Labs</Link> to forecast real-world events
                    </p>
                  </TimelineItem>
                  <TimelineItem date="June 2016">
                    <p>
                      Vox Pop Labs predicts several global political outcomes, including Brexit, 10 days before the
                      vote
                    </p>
                  </TimelineItem>
                  <TimelineItem date="August 2017">
                    <p>
                      Future Delphia CEO, Andrew Peek, and van der Linden adapt Vox Pops predictive models to financial
                      markets
                    </p>
                  </TimelineItem>
                  <TimelineItem date="January 2018">
                    <p>
                      Delphia spins out of Vox Pop Labs, is accepted into Y Combinator, and closes two rounds of
                      funding within the year
                    </p>
                  </TimelineItem>
                </Timeline>
              </AccordionItem>
              <AccordionItem textAlignment="left" backgroundColor={COLORS.purple400} title="Delphia">
                <Timeline>
                  <TimelineItem date="2019">
                    <p>
                      Delphia stops selling its predictions to Wall Street and registers as an Investment Advisor with
                      the SEC
                    </p>
                  </TimelineItem>
                  <TimelineItem date="June 2020">
                    <p>Delphia brings on Jonathan Briggs as Chief Investment Officer</p>
                  </TimelineItem>
                  <TimelineItem date="April 2021">
                    <p>Delphia launches an institutional-grade market-neutral quant equity strategy</p>
                  </TimelineItem>
                  <TimelineItem date="April 2022">
                    <p>Delphia's strategy achieves one of the all-time top 5 years of quant equity performance</p>
                  </TimelineItem>
                  <TimelineItem date="June 2022">
                    <p>
                      Multicoin Capital, with participation from Ribbit Capital and Valor, leads a $60m funding round
                      into Delphia
                    </p>
                  </TimelineItem>
                  <TimelineItem date="2024">
                    <p>
                      Delphia shuts down its data reward token and robo-advisor, refocuses on professional investors.
                    </p>
                  </TimelineItem>
                </Timeline>
              </AccordionItem>
              <AccordionItem textAlignment="left" backgroundColor={COLORS.purple400} title="Delphia Holdings">
                <Timeline>
                  <TimelineItem date="March 2023">
                    <p>
                      Delphia helps to establish Superset as a Data Trust to ensure 'fair trade' compensation for
                      peoples' data
                    </p>
                  </TimelineItem>
                  <TimelineItem date="June 2023">
                    <p>
                      Delphia open sources its backtesting framework,{' '}
                      <Link to="https://github.com/forecastos/investos">InvestOS</Link>, and spins out{' '}
                      <Link to="https://forecastos.com/">ForecastOS</Link> as a new venture
                    </p>
                  </TimelineItem>
                  <TimelineItem date="May 2024">
                    <p>
                      Delphia partners with an experienced group of asset management executives to grow its hedge fund,
                      Oracle Alpha
                    </p>
                  </TimelineItem>
                  <TimelineItem date="Coming Soon">
                    <p>Delphia launches Tilt as a way for anyone to invest in the trends they believe in</p>
                  </TimelineItem>
                  <TimelineItem date="Coming Soon">
                    <p>Delphia launches Backchannel as a way for experts to own and monetize their knowledge</p>
                  </TimelineItem>
                </Timeline>
              </AccordionItem>
            </Accordion>
          )}
        </TimeLineWrapper>
      </Container>
    </Wrapper>
  );
};

export default DelphiaJourney;

const Wrapper = styled.section`
  ${speckledBackground(COLORS.purple400)}
  padding: 40px 0 0 0;

  ${media.medium`
    padding: 96px 0 0 0;
`}
`;

const TimeLineWrapper = styled.div`
  margin-top: 32px;
  ${media.medium`
    margin-top: 96px;
  `}
`;

const TabSwitcherWrapper = styled.div`
  margin-top: 40px;
`;

const TabSwitcher = styled.div`
  display: flex;
  background-color: ${COLORS.purple300};
  border-radius: 999px;
  padding: 8px;
  gap: 4px;
`;
const TabButton = styled.button<{ isSelected?: boolean }>`
  background-color: ${({ isSelected }) => (isSelected ? `${COLORS.white}` : 'transparent')};
  color: ${({ isSelected }) => (isSelected ? `${COLORS.purple400}` : `${COLORS.white}`)};

  height: 56px;
  padding: 12px 24px;
  border-radius: 999px;
  border: none;
  width: 180px;
  ${typography.h4}
  cursor: pointer;

  :hover {
    background-color: ${({ isSelected }) => (isSelected ? 'inital' : 'rgba(255, 255, 255, 0.1)')};
  }
`;

const ProductsList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 48px;
  padding-bottom: 40px;

  ${media.medium`
    padding-bottom: 96px;
  `}
`;

const ProductItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-bottom: 4px dotted ${COLORS.blue100};
  width: 100%;

  ${media.medium`
    width: calc(50% - 24px);
  `}
`;

const ProductImage = styled.div<{ bgColor: string }>`
  background-color: ${({ bgColor }) => bgColor};
  width: 100%;
  aspect-ratio: 2/1;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
`;

const ProductLogo = styled.img`
  height: 50px;
  width: auto;
  max-width: 60%;

  ${media.medium`
    height:80px;
    max-width: 50%;
  `};
`;
