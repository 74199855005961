import { LogoTextLinkBlock } from '@cheese-fondue/components/logoTextLinkBlock/logoTextLinkBlock';
import { PressGallery } from '@cheese-fondue/components/pressGallery/PressGallery';
import { PageProps } from 'gatsby';
import React, { ReactElement } from 'react';
import SmallHeaderLayout from '@cheese-fondue/layout/smallHeaderLayout';
import { useImageQuery } from '@cheese-fondue/helpers';

import { HeroSecondary } from '@cheese-fondue/components/heroSecondary/heroSecondary';
import GetEdge from '@cheese-fondue/components/getEdge';
import OrangeAsciiWall from '@cheese-fondue/components/orangeAsciiWall';
import DelphiaJourney from '@cheese-fondue/components/delphiaJourney';
const Page = (props: PageProps): ReactElement => {
  const githubLogo = useImageQuery({ src: 'images/mediaLogos/github-dark.png' });
  const forecastOSLogo = useImageQuery({ src: 'images/mediaLogos/forecastOS.png' });
  const supersetLogo = useImageQuery({ src: 'images/mediaLogos/superset.png' });
  const huggingFaceLogo = useImageQuery({ src: 'images/mediaLogos/huggingface.png' });
  const triangleBgWide = useImageQuery({ src: 'images/triangle-bg-wide.png' });
  const triangleBg = useImageQuery({ src: 'images/triangle-bg.png' });
  const youTubeLogo = useImageQuery({ src: 'images/mediaLogos/youtube.png' });

  if (
    !triangleBgWide ||
    !triangleBg ||
    !githubLogo ||
    !forecastOSLogo ||
    !supersetLogo ||
    !huggingFaceLogo ||
    !youTubeLogo
  ) {
    throw new Error('home page images not found');
  }

  const logoTextLinkBlockData = [
    {
      imageData: forecastOSLogo,
      imageAltText: 'ForecastOS logo',
      text:
        'Delphia spins out ForecastOS – a way for anyone to build a quantitative investment strategy with confidence',
      link: {
        linkText: 'Get ForecastOS',
        linkPath: 'https://forecastos.com/',
        target: '_blank',
      },
    },
    {
      imageData: githubLogo,
      imageAltText: 'Github logo',
      text:
        'Delphia and ForecastOS open source an institutional-grade portfolio engineering and backtesting framework',
      link: {
        linkText: 'View on Github',
        linkPath: 'https://github.com/forecastos/investos',
        target: '_blank',
      },
    },
    {
      imageData: supersetLogo,
      imageAltText: 'Superset logo',
      text: `Delphia helps to establish Superset as a Data Trust to ensure "fair trade" compensation for peoples' data`,
      link: {
        linkText: 'Read More',
        linkPath: 'https://www.trustsuperset.com/',
        target: '_blank',
      },
    },
    {
      imageData: huggingFaceLogo,
      imageAltText: 'Huggingface logo',
      text: `Delphia begins open sourcing parts of it's news classification engine on Hugging Face`,
      link: {
        linkText: 'Read More',
        linkPath: 'https://huggingface.co/Delphia/twitter-spam-classifier',
        target: '_blank',
      },
    },
    {
      imageData: youTubeLogo,
      imageAltText: 'YouTube logo',
      text: `Delphia's CEO interviews the Co-Founder of Cosmos on The Future of Wealth`,
      link: {
        linkText: 'Watch on YouTube',
        linkPath: 'https://www.youtube.com/watch?v=lrkzyF7nfys',
        target: '_blank',
      },
    },
  ];

  const linkAndTextData = [
    {
      linkText: 'AI & National Policy',
      linkUrl: 'https://zenodo.org/records/10845249',
      text:
        'What we believe needs to be done to ensure a safe, competitive, and aligned AI future in the United States.',
    },
    {
      linkText: 'Capital Markets & Data',
      linkUrl: 'https://docs.delphia.com/the-delphia-thesis',
      text: 'How capital markets and alternative (consumer) data can offer us a lifeline to a Sovereign Wealth Fund.',
    },
    {
      linkText: 'Letter from the CEO',
      linkUrl: 'https://medium.com/@drupeek/4e98ede9019d',
      text:
        'For Investors, Policymakers, Data Rights Advocates, and Entrepreneurs who may be learning of Delphia for the first time.',
    },
  ];

  return (
    <SmallHeaderLayout location={props.location} blueOnOrange>
      <HeroSecondary
        bgImage={triangleBg}
        bottomImage={triangleBgWide}
        title="Delphia"
        subtitle="Delphia is a collection of entrepreneurs who believe that
        in an AI-driven world, data becomes our most important asset."
        linkText="Join the conversation"
        linkUrl="https://discord.gg/PTCx5rd2Ws"
        linkAndTextData={linkAndTextData}
      />
      <GetEdge />
      <DelphiaJourney />
      <PressGallery />
      <LogoTextLinkBlock data={logoTextLinkBlockData} />
      <OrangeAsciiWall />
    </SmallHeaderLayout>
  );
};

export default Page;
